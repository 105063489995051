
import Modal from '@/components/Shared/Modal/index.vue'
import UploadImageArea from './UploadImageArea.vue'
import AdminContentEditable from '~/mixins/AdminContentEditable'

export default {
  mixins: [AdminContentEditable],
  props: {
    value: {
      default: false,
    },
    type: {
      default: 0,
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        overview: '',
      },
      image: null,
    }
  },
  components: {
    Modal,
    UploadImageArea,
  },
  methods: {
    SEND_CONTENT(e) {
      e.preventDefault()
      if (this.image === null) return
      // (val, req, key, type, id)
      this.UPDATE_CONTEXT(
        this.form.title,
        'Contents?companyId=' + this.getSettings.id,
        'title',
        this.type,
        -1
      )
      this.UPDATE_CONTEXT(
        this.form.description,
        'Contents?companyId=' + this.getSettings.id,
        'description',
        this.type,
        -1
      )
      this.UPDATE_CONTEXT(
        this.form.overview,
        'Contents?companyId=' + this.getSettings.id,
        'overview',
        this.type,
        -1
      )
      this.UPDATE_CONTEXT(
        'TR',
        'Contents?companyId=' + this.getSettings.id,
        'language',
        this.type,
        -1
      )
      this.UPDATE_CONTEXT(
        this.image.file,
        'Contents?companyId=' + this.getSettings.id,
        'imagePath',
        this.type,
        -1
      )
      this.UPDATE_CONTEXT(
        this.type,
        'Contents?companyId=' + this.getSettings.id,
        'type',
        this.type,
        -1
      )
      this.SAVE_CONTEXT(
        'POST',
        'Contents?companyId=' + this.getSettings.id,
        -1,
        ['title', 'description', 'overview', 'language', 'imagePath', 'type'],
        this.SAVE_CONTENT
      )
    },
    SAVE_CONTENT() {
      location.reload()
    },
    GET_FILE(image) {
      this.image = image
    },
  },
}
