
import Modal from '@/components/Shared/Modal/index.vue'
import AdminContentEditable from '~/mixins/AdminContentEditable'
export default {
  mixins: [AdminContentEditable],
  components: {
    Modal,
  },
  props: {
    value: {
      default: () => ({ show: false, id: -1 }),
    },
  },
  methods: {
    UPDATE_CONTENT() {
      this.$emit('removed:item', this.value.id)
      this.alertBox.status = false;
    },
  },
}
